<template>
  <div class="w-100 my-5">
    <div v-if="transactionList">
      <div class="row mx-auto">
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('ticket_price') }}</span>
          <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(ticketPrice) }}</span>
          <div>
            <span class="font-15 color-red">{{ getPriceFormat(ticketPriceRefunded) }}</span>
            <span class="ml-2 font-12 alpha-07">{{ $t('refunded') }}</span>
          </div>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-red">&nbsp;&nbsp;&nbsp;{{ $t('service_charge') }}</span>
          <span class="mt-3 font-bold font-50 color-red">{{ getPriceFormat(serviceCharge) }}</span>
          <div>
            <span class="font-15 color-red">{{ getPriceFormat(serviceChargeRefunded) }}</span>
            <span class="ml-2 font-12 alpha-07">{{ $t('refunded') }}</span>
          </div>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('total_price') }}</span>
          <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(totalPrice) }}</span>
          <div>
            <span class="font-15 color-red">{{ getPriceFormat(totalPriceRefunded) }}</span>
            <span class="ml-2 font-12 alpha-07">{{ $t('refunded') }}</span>
          </div>
        </div>
      </div>
      <div class="row mx-auto">
        <div class="col-sm m-2 box-white row vertical-center">
          <div class="col-sm my-1 vertical-center" v-if="userInfo">
            <img class="circle-image-30" :src="userInfo.thumbnailUrl || userInfo.photoUrl || assets.profile" />
            <span class="ml-2 font-15 font-bold">{{ userInfo.userName }}</span>
          </div>
          <div class="col-sm my-1 vertical-center" v-else-if="eventInfo">
            <img :width="60" :height="40" :src="eventInfo.thumbnailUrl || eventInfo.photoUrl" />
            <span class="ml-2 font-15 font-bold">{{ eventInfo.name }}</span>
          </div>
          <div class="col-sm my-1 vertical-center">
            <span class="font-bold font-15 alpha-07">{{ $t('transaction') }}:</span>
            <span class="mx-5 font-bold font-20 color-blue">{{ items.length }}</span>
          </div>
          <DatePicker
            class="col-sm my-1"
            v-model="selectedMonth"
            type="month"
            @change="onMonthChanged"
            :placeholder="$t('select_month')"
          />
          <DatePicker
            class="col-sm my-1"
            v-model="dateRange"
            type="date"
            range
            @change="onDateRangeChanged"
            :placeholder="$t('select_date_range')"
          />
          <div :class="`col-sm my-1 vertical-center ${isMobile ? '' : 'justify-content-end'}`">
            <input class="w-75" v-model="keyword" />
            <v-btn class="ml-2" icon small @click="keyword = ''" :disabled="!keyword">
              <i :class="`flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="row mx-auto" v-for="(item, index) in items.slice(0, Math.min(pageIndex * PAGE_LIMIT_10, items.length))" :key="(item, index)">
        <div class="col-sm m-2 p-0 box-white row">
          <div class="col-sm d-flex flex-column">
            <span class="m-2 font-12" v-if="hostname === 'localhost'">ID: {{ item.transactionId }}</span>
            <div class="my-1 vertical-center">
              <img class="circle-image-30" :src="item.organizerPhotoUrl" />
              <span class="ml-2 ellipsis font-bold font-12" :title="item.organizerName">{{ item.organizerName }}</span>
            </div>
            <img width="200px" :src="item.eventPhotoUrl" />
            <span class="my-1 w-250px ellipsis font-bold font-12" :title="item.eventName">{{ item.eventName }}</span>
          </div>
          <div class="vertical-center">
            <div class="my-5 div-divider-h" v-if="isMobile" />
            <div class="mx-3 div-divider-v" v-else />
          </div>
          <div class="col-sm-4 d-flex flex-column">
            <div :class="`my-2 vertical-center ${item.refundRequestedAt ? '' : 'pointer'}`" @click="gotoTicketList(item)">
              <span class="font-bold font-15">{{ $t('tickets') }}</span>
              <span class="ml-2 fa fa-angle-double-right font-15 color-blue" v-if="!item.refundRequestedAt"></span>
            </div>
            <div class="mt-2 d-flex justify-content-between vertical-center" v-for="(detail, index2) in item.details" :key="(detail, index2)">
              <span class="w-50 font-12" v-if="detail.eventDays">{{ `${detail.ticketName} (${$t('event_days')}: ${detail.eventDays})` }}</span>
              <span class="w-50 font-12" v-else>{{ detail.ticketName }}</span>
              <span class="ml-auto font-12">{{ `${getPriceFormat(detail.price)} * ${detail.quantity} = ${getPriceFormat(detail.price * detail.quantity)}` }}</span>
            </div>
            <span class="mt-5 font-12 color-red" v-if="item.promoCode && item.promoPercent">{{ $t('discounted_', [item.promoPercent, item.promoCode]) }}</span>
          </div>
          <div class="vertical-center">
            <div class="my-5 div-divider-h" v-if="isMobile" />
            <div class="mx-3 div-divider-v" v-else />
          </div>
          <div class="col-sm-3 d-flex flex-column">
            <div class="my-1 vertical-center">
              <img class="circle-image-30" :src="item.buyerPhotoUrl" />
              <span class="ml-2 ellipsis font-12 font-bold" :title="item.buyerName">{{ item.buyerName }}</span>
            </div>
            <span :class="`mt-2 ellipsis font-12 ${item.deviceTypeColor}`" :title="item.deviceType" v-if="item.deviceType">{{ item.deviceType }}</span>
            <div class="mt-5 d-flex justify-content-between">
              <span class="font-12">{{ $t('ticket_price') }}</span>
              <span class="ml-2 font-12">{{ getPriceFormat(item.ticketPrice) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between" v-if="item.promoDiscount">
              <span class="font-12">{{ $t('promo_discount') }}</span>
              <span class="ml-2 font-12 color-red">{{ getPriceFormat(item.promoDiscount * -1) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between">
              <span class="font-12">{{ $t('service_charge') }}</span>
              <span class="ml-2 font-12">{{ getPriceFormat(item.serviceCharge) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between">
              <span class="font-15">{{ $t('total_price') }}</span>
              <span class="ml-2 font-bold font-15">{{ getPriceFormat(item.totalPrice) }}</span>
            </div>
            <div class="my-2 d-flex justify-content-between">
              <span class="font-12">{{ $t('handling_fee') }}</span>
              <span class="ml-2 font-12">{{ getPriceFormat(item.handlingFee || 0) }}</span>
            </div>
          </div>
          <div class="vertical-center">
            <div class="my-5 div-divider-h" v-if="isMobile" />
            <div class="mx-3 div-divider-v" v-else />
          </div>
          <div class="col-sm d-flex flex-column">
            <div class="my-2 d-flex justify-content-between vertical-center" v-if="hostname === 'localhost' && (item.transactionId.startsWith(PAYMENT_TYPE_PAYPAL_SANDBOX) || item.transactionId.startsWith(PAYMENT_TYPE_STRIPE_TEST))">
              <span class="my-2 font-bold font-15 color-red">Sandbox</span>
              <v-btn icon small @click="deleteTransactionInfoTask(item.transactionId)">
                <i class="flaticon-delete color-red"></i>
              </v-btn>
            </div>
            <span class="mt-2 font-bold font-15 color-red" v-else-if="item.refundRequestedAt">{{ $t('refund') }}</span>
            <span class="mt-2 font-bold font-15 color-blue" v-else>{{ $t('paid') }}</span>
            <div class="mt-2 d-flex flex-column" v-if="hostname === 'localhost' && item.type">
              <span class="font-12 font-bold color-red">{{ item.type }}</span>
              <span class="ml-auto font-12">{{ item.transactionId }}</span>
            </div>
            <div class="mt-2 d-flex vertical-center">
              <img height="30px" :src="item.transactionId.startsWith(PAYMENT_TYPE_PAYPAL) ? assets.paypal : assets.stripe" />
              <img class="ml-2" height="30px" :src="assets[item.paymentMethod]" v-if="item.paymentMethod && item.paymentMethod !== 'paypal'" />
              <span class="ml-auto font-12">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between" v-if="item.refundRequestedAt">
              <span class="font-12">{{ $t('requested') }}</span>
              <span class="ml-2 font-bold font-12">{{ getDateStringFromTimestamp(item.refundRequestedAt) }}</span>
            </div>
            <v-btn class="mt-5 ml-auto button-normal" small @click="requestRefundTask(item.transactionId)" v-else>
              <span>{{ $t('request_refund') }}</span>
            </v-btn>
            <div class="mt-2 d-flex justify-content-between" v-if="item.refundRequestedBy">
              <span class="font-12 color-red">{{ $t('requested_by') }}</span>
              <span class="ml-2 font-bold font-12 color-red">{{ item.refundRequestedBy }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between" v-if="item.refundCanceledBy">
              <span class="font-12 color-red">{{ $t('refund_canceled_by') }}</span>
              <span class="ml-2 font-bold font-12 color-red">{{ item.refundCanceledBy }}</span>
            </div>
            <div class="mt-5 d-flex justify-content-between" v-if="item.refundPaidAt">
              <span class="font-12">{{ $t('paid') }}</span>
              <span class="ml-2 font-12 font-bold color-red">{{ getDateStringFromTimestamp(item.refundPaidAt) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between" v-if="item.refundPaidBy">
              <span class="font-12 color-red">{{ $t('paid_by') }}</span>
              <span class="ml-2 font-bold font-12 color-red">{{ item.refundPaidBy }}</span>
            </div>
            <div class="mt-2" v-if="isMobile">&nbsp;</div>
          </div>
        </div>
      </div>
      <div class="my-5 d-flex justify-content-center" v-if="items.length / PAGE_LIMIT_10 > pageIndex">
        <v-btn text small @click="pageIndex++">
          <span>Load More</span>
        </v-btn>
      </div>
      <div class="py-40 center" v-if="(items.length === 0)">
        <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getPriceFormat, getDateStringFromTimestamp, getDateStringFromDate, showLoading } from '../../../functions';

import profile from '@/assets/image/profile.jpg';
import paypal from '@/assets/image/paypal.png';
import stripe from '@/assets/image/stripe.png';
import card from '@/assets/image/card.png';
import sepa from '@/assets/image/sepa.png';
import sepa_debit from '@/assets/image/sepa.png';
import sofort from '@/assets/image/sofort.png';
import klarna from '@/assets/image/klarna.png';
import giropay from '@/assets/image/giropay.png';

export default {
  name: 'TransactionList',
  components: {
    DatePicker
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    userList() {
      return this.$store.state.userList || [];
    },
    userInfo() {
      if (this.$route.params.userId) {
        return this.userList.find(element => element.userId === this.$route.params.userId);
      } else {
        return null;
      }
    },
    eventList() {
      return this.$store.state.eventList || [];
    },
    eventInfo() {
      if (this.$route.params.eventId) {
        return this.eventList.find(element => element.eventId === this.$route.params.eventId);
      } else {
        return null;
      }
    },
    transactionList() {
      return this.$store.state.transactionList;
    },
    params() {
      return this.$route.params;
    }
  },
  watch: {
    params() {
      if (this.keyword) {
        this.keyword = '';
      } else {
        this.refreshData();
      }
    },
    eventList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    userList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    transactionList() {
      this.refreshData();
    },
    keyword() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        profile,
        paypal,
        stripe,
        card,
        sepa,
        sepa_debit,
        sofort,
        klarna,
        giropay
      },
      hostname: window.location.hostname,
      selectedMonth: null,
      dateRange: null,
      ticketPrice: 0,
      serviceCharge: 0,
      totalPrice: 0,
      ticketPriceRefunded: 0,
      serviceChargeRefunded: 0,
      totalPriceRefunded: 0,
      pageIndex: 1,
      cacheEventList: [],
      cacheUserList: [],
      items: [],
      keyword: ''
    }
  },
  mounted() {
    this.setDateRange();
    this.refreshData();
  },
  methods: {
    getPriceFormat,
    getDateStringFromTimestamp,
    setDateRange() {
      if (!this.$route.params.userId && !this.$route.params.eventId) {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        this.dateRange = [startDate, endDate];
      }
    },
    onMonthChanged(value, type) {
      if (value) {
        this.dateRange = [null, null];
      } else {
        this.setDateRange();
      }
      this.refreshData();
    },
    onDateRangeChanged(value, type) {
      if (value && value.length === 2 && value[0] && value[1]) {
        this.selectedMonth = null;
      } else {
        this.selectedMonth = new Date();
      }
      this.refreshData();
    },
    refreshData() {
      if (this.transactionList && this.userList && this.eventList) {
        var ticketPrice = 0;
        var serviceCharge = 0;
        var totalPrice = 0;
        var ticketPriceRefunded = 0;
        var serviceChargeRefunded = 0;
        var totalPriceRefunded = 0;
        const items = [];
        const keyword = this.keyword.toLowerCase();
        this.transactionList.filter(element => this.filterTransaction(element)).forEach(transactionInfo => {
          const buyerInfo = this.getUserInfo(transactionInfo.buyerId);
          const organizerInfo = this.getUserInfo(transactionInfo.organizerId);
          const eventInfo = this.getEventInfo(transactionInfo.eventId);
          if (buyerInfo && organizerInfo && eventInfo &&
            (eventInfo.name.toLowerCase().includes(keyword) || buyerInfo.userName.toLowerCase().includes(keyword) || organizerInfo.userName.toLowerCase().includes(keyword))) {
            const item = JSON.parse(JSON.stringify(transactionInfo));
            item['buyerName'] = buyerInfo.userName;
            item['buyerPhotoUrl'] = buyerInfo.thumbnailUrl || buyerInfo.photoUrl || this.assets.profile;
            item['organizerName'] = organizerInfo.userName;
            item['organizerPhotoUrl'] = organizerInfo.thumbnailUrl || organizerInfo.photoUrl || this.assets.profile;
            item['eventName'] = eventInfo.name;
            item['eventPhotoUrl'] = eventInfo.thumbnailUrl || eventInfo.photoUrl;
            if (window.location.hostname === 'localhost') {
              item['deviceType'] = buyerInfo.deviceType;
              item['deviceTypeColor'] = buyerInfo.deviceTypeColor;
            }
            ticketPrice += transactionInfo.ticketPrice - (transactionInfo.promoDiscount || 0);
            serviceCharge += transactionInfo.serviceCharge;
            totalPrice += transactionInfo.totalPrice;
            if (transactionInfo.refundRequestedAt) {
              ticketPriceRefunded += transactionInfo.ticketPrice - (transactionInfo.promoDiscount || 0);
              serviceChargeRefunded += transactionInfo.serviceCharge;
              totalPriceRefunded += transactionInfo.totalPrice;
            }
            items.push(item);
          }
        });
        items.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
        this.pageIndex = 1;
        this.items = items;
        this.ticketPrice = ticketPrice;
        this.serviceCharge = serviceCharge;
        this.totalPrice = totalPrice;
        this.ticketPriceRefunded = ticketPriceRefunded;
        this.serviceChargeRefunded = serviceChargeRefunded;
        this.totalPriceRefunded = totalPriceRefunded;
      }
    },
    gotoTicketList(item) {
      if (!item.refundRequestedAt) {
        this.$router.push(`/transaction/tickets/${item.transactionId}`)
      }
    },
    filterTransaction(element) {
      if (window.location.hostname !== 'localhost' && element.transactionId.startsWith(this.PAYMENT_TYPE_PAYPAL_SANDBOX)) {
        return false;
      }
      if (this.selectedMonth && getDateStringFromDate(this.selectedMonth, 'YYYY-MM') !== getDateStringFromTimestamp(element.createdAt, 'YYYY-MM')) {
        return false;
      }
      if (this.dateRange && this.dateRange.length === 2 && this.dateRange[0] && this.dateRange[1]) {
        const createdTime = element.createdAt.toDate().getTime();
        if (createdTime < this.dateRange[0].getTime() || createdTime > this.dateRange[1].getTime()) {
          return false;
        }
      }
      if (this.$route.params.userId) {
        return element.buyerId === this.$route.params.userId || element.organizerId === this.$route.params.userId;
      } else if (this.$route.params.eventId) {
        return element.eventId === this.$route.params.eventId;
      } else {
        return true;
      }
    },
    getUserInfo(userId) {
      const cacheInfo = this.cacheUserList.find(element => element.userId === userId);
      if (cacheInfo) {
        return cacheInfo;
      } else {
        const userInfo = this.userList ? this.userList.find(element => element.userId === userId) : null;
        if (userInfo) {
          this.cacheUserList.push(userInfo);
        }
        return userInfo;
      }
    },
    getEventInfo(eventId) {
      const cacheInfo = this.cacheEventList.find(element => element.eventId === eventId);
      if (cacheInfo) {
        return cacheInfo;
      } else {
        const eventInfo = this.eventList ? this.eventList.find(element => element.eventId === eventId) : null;
        if (eventInfo) {
          this.cacheEventList.push(eventInfo);
        }
        return eventInfo;
      }
    },
    deleteTransactionInfoTask(transactionId) {
      const params = {
        functionName: 'deleteTransactionInfo',
        transactionId: transactionId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    requestRefundTask(transactionId) {
      if (!transactionId) {
        this.$toast.error('Unexpected error occurs.');
        return;
      }
      if (!confirm('Are you sure you want to request the refund for this payment?')) {
        return;
      }
      const params = {
        functionName: 'requestRefund',
        transactionId: transactionId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success('Requested successfully.');
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>